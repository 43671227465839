<template>
  <div>
    <!--begin::Card-->
    <div class="card-body">
      <!--begin::Details-->
      <div class="d-flex">
        <!--begin: Pic-->
        <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
          <div class="symbol symbol-50 symbol-lg-120">
            <v-img
              class="profile-image"
              width="100"
              height="100"
              :lazy-src="$defaultProfileImage"
              :src="getProfileImage"
            >
            </v-img>
          </div>

          <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
            <span class="font-size-h3 symbol-label font-weight-boldest">{{
              currentUser.display_name
                ? currentUser.display_name.charAt(0).toUpperCase()
                : ""
            }}</span>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div class="d-flex justify-content-between flex-wrap mt-1">
            <div class="d-flex mr-3">
              <a
                href="#"
                class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                >{{ currentUser.full_name }}</a
              >
              <template v-if="currentUser.activated">
                <a
                  href="#"
                  :content="`<span class=&quot;font-size-13&quot;>Account is activated</span>`"
                  v-tippy="{ arrow: true, animation: 'fade' }"
                  ><i class="flaticon2-correct text-success font-size-h5"></i
                ></a>
              </template>
            </div>
          </div>
          <!--end::Title-->

          <!--begin::Content-->
          <div class="d-flex flex-wrap justify-content-between mt-1">
            <div class="d-flex flex-column flex-grow-1 pr-8">
              <div class="d-flex flex-wrap mb-4">
                <a
                  :content="`<span class=&quot;font-size-13&quot;>User Email</span>`"
                  v-tippy="{ arrow: true, animation: 'fade' }"
                  :href="'mailto:' + currentUser.user_email"
                  class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  ><i class="flaticon2-new-email mr-2 font-size-lg"></i
                  >{{ currentUser.user_email }}</a
                >
                <a
                  :content="`<span class=&quot;font-size-13&quot;>User Name</span>`"
                  v-tippy="{ arrow: true, animation: 'fade' }"
                  href="#"
                  class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  ><i class="flaticon2-user mr-2 font-size-lg"></i
                  >{{ currentUser.user_name }}</a
                >
                <a
                  :content="`<span class=&quot;font-size-13&quot;>Phone Number</span>`"
                  v-tippy="{ arrow: true, animation: 'fade' }"
                  :href="'tel:' + currentUser.phone_number"
                  class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  ><i class="flaticon2-phone mr-2 font-size-lg"></i
                  >{{ currentUser.phone_number }}</a
                >
                <a
                  :content="`<span class=&quot;font-size-13&quot;>User Role</span>`"
                  v-tippy="{ arrow: true, animation: 'fade' }"
                  href="#"
                  class="text-dark-50 text-hover-primary text-uppercase font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  ><i class="flaticon2-calendar-3 mr-2 font-size-lg"></i
                  >{{ currentUser.role_name }}
                </a>
              </div>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->
      <!--begin::Profile-->
      <v-tabs
        v-model="userTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#tab-' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 89vh position: relative"
      >
        <v-tabs-items v-model="userTab">
          <v-tab-item :value="'tab-history'">
            <RecentActivity :user="userId"></RecentActivity>
          </v-tab-item>
          <v-tab-item :value="'tab-password'">
            <PasswordUpdate
              isOldPassword
              :user="userId"
              v-on:password:updated="logoutUser"
            ></PasswordUpdate>
          </v-tab-item>
          <v-tab-item :value="'tab-login-log'">
            <LoginLog :user="userId" :logs="loginLogs"></LoginLog>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
      <!--end::Profile-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LoginLog from "@/view/pages/user/detail/LoginLog";
import PasswordUpdate from "@/view/pages/user/detail/PasswordUpdate";
import RecentActivity from "@/view/pages/user/detail/RecentActivity";
import { GET } from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "basic-profile",
  data() {
    return {
      userId: 0,
      userTab: null,
      pageLoading: true,
      currentUser: new Object(),
      userData: new Object(),
      loginLogs: [],
      tabs: [
        {
          title: "Recent Activities",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
        {
          title: "Login Log",
          icon: "mdi-credit-card",
          key: "login-log",
          disabled: false,
        },
        {
          title: "Password",
          icon: "mdi-credit-card",
          key: "password",
          disabled: false,
        },
      ],
    };
  },
  components: {
    LoginLog,
    PasswordUpdate,
    RecentActivity,
  },
  methods: {
    logoutUser() {
      JwtService.destroyAuth();
    },
    getUserProfile() {
      const _this = this;
      try {
        _this.currentUser = JwtService.currentUser();
        _this.userId = _this.currentUser.id;
      } catch (error) {
        _this.logError(error);
      }
    },
    getUser() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "user/" + _this.userId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    loadContent() {
      const _this = this;
      _this.pageLoading = true;
      _this
        .getUser()
        .then((response) => {
          _this.userData = response;
          _this.loginLogs = response.login_details || [];
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getUserProfile();
    this.loadContent();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Profile" },
      { title: "Basic" },
    ]);
  },
  computed: {
    getProfileImage() {
      if (
        this.lodash.isEmpty(this.currentUser) === false &&
        this.lodash.isEmpty(this.currentUser.profile_logo) === false
      ) {
        return this.currentUser.profile_logo.file.url;
      }
      return null;
    },
  },
};
</script>
